import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import {
  reactRevealDuration,
  reactRevealDistance,
} from '#globals/constants'

const styles = {
  contaner: css`
    background-color: ${colors.white};
    ${breakpoints({
      paddingTop: [80, 80, 60, 140, 140],
      paddingBottom: [80, 80, 60, 45, 45],
    })};
  `,
  title: css`
    ${spacer.mrB10}
  `,
  text: css`
    line-height: 1.5;
    ${spacer.mrB40}
  `,
  img: css`
    position: relative;
    width: 100%;
    z-index: 1;
  `,
  popcorn: css`
    ${breakpoints({
      top: [0, 0, 0, -270, -300],
      left: [0, 0, -50, -200, -200],
    })};
  `,
}

function Note() {
  return (
    <section css={styles.contaner}>
      <div className='container'>
        <div className='row'>
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='offset-lg-1 col-lg-8 offset-md-0 col-md-8 text-md-left text-center'>
              <Title
                as='h2'
                size='xxxxLarge'
                weight='bold'
                color={colors.black}
                css={styles.title}
              >
                A note from <br />
                Faisal
              </Title>
              <Text
                size='large'
                color={colors.black}
                css={styles.text}
              >
                Faisal Nasim is the Director of Daily Quest Plus.
              </Text>
              <Text
                size='large'
                color={colors.black}
                css={styles.text}
              >
                I was blessed with supportive parents, who wanted to
                see me excel educationally. Due to their help, I was
                able to gain two Scholarships that allowed me to study
                at St. Paul’s School. I then went on to graduate from
                Cambridge University. After working in finance for a
                while, I decided to change course and pursue my two
                main passions: entrepreneurship and education.
                <br />
                <br />
                Since then, I’ve started and grown a number of
                companies that produce tools and resources to help
                students maximise their potential and ace their exams.
                These products are used successfully by hundreds of
                thousands of students every year.
                <br />
                <br />
                I'm passionate about helping children access the
                benefits of an amazing education, just like I did.
              </Text>
            </div>
          </Fade>
          <Fade
            right
            distance={reactRevealDistance}
            duration={reactRevealDuration}
          >
            <div className='col-lg-3 col-md-4'>
              <Image
                lazy
                css={styles.popcorn}
                className='d-none d-md-block'
                alt='popcorn'
                image='about/popcorn'
                sizes={{
                  lg: {
                    width: 686,
                  },
                  md: {
                    width: 500,
                  },
                }}
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default Note
