import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import { Power0 } from 'gsap'
import Fade from 'react-reveal/Fade'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { LinkBtn } from '@dqp/common/components/Button/Button'
import Image from '#components/Image'
import {
  timelineY,
  reactRevealDistance,
  reactRevealDuration,
} from '#globals/constants'
import ScrollParallax from '#lib/ScrollParallax'
import routes from '#globals/routes'

const styles = {
  contaner: css`
    background-color: ${colors.purple};
    ${breakpoints({
      paddingTop: [40, 40, 140, 140, 160],
      paddingBottom: [80, 80, 120, 170, 170],
    })};
  `,
  title: css`
    ${spacer.mrB30};
  `,
  text: css`
    line-height: 1.5;
    ${spacer.mrB30}
  `,
  cog: css`
    ${breakpoints({
      top: [0, 0, -240, -270, -270],
      right: [0, 0, -25, 0, 0],
    })};
  `,
}
function Hero() {
  const cog = useRef(null)

  useEffect(() => {
    const cogOptions = {
      options: {
        SMtriggerElement: cog.current,
        SMduration: '100%',
        SMtriggerHook: 0.14,
        GSelement: cog.current,
        GSduration: 1,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const cogParallax = new ScrollParallax()

    cogParallax.init(
      cogOptions.options,
      cogOptions.timelineVarsStart,
      cogOptions.timelineVarsEnd,
    )
    return () => {
      cogParallax.destroyController()
    }
  }, [])
  return (
    <section css={styles.contaner}>
      <div className='container'>
        <div className='row align-items-center'>
          <Fade
            left
            distance={reactRevealDistance}
            duration={reactRevealDuration}
            ssrReveal
          >
            <div className='col-lg-5 col-md-3'>
              <Image
                ref={cog}
                css={styles.cog}
                className='d-none d-md-block'
                alt='cog'
                image='about/cog'
                sizes={{
                  lg: {
                    width: 700,
                  },
                  md: {
                    width: 480,
                  },
                }}
              />
            </div>
          </Fade>
          <div className='col-lg-6 offset-md-1 col-md-8 text-md-left text-center'>
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.white}
              css={styles.title}
            >
              About Daily Quest Plus
            </Title>
            <Text size='large' color={colors.white} css={styles.text}>
              Daily Quest Plus is a new and unique programme to help
              your child prepare for their 11+ exam.
            </Text>
            <LinkBtn
              variant='black'
              size='large'
              inline
              to={routes.howitworks}
            >
              HOW IT WORKS
            </LinkBtn>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
