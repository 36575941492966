import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import { Power0 } from 'gsap'
import Fade from 'react-reveal/Fade'
import { colors, breakpoints } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import doubleQuotes from '#images/doubleQuotes.svg'
import { timelineY, reactRevealDuration } from '#globals/constants'
import ScrollParallax from '#lib/ScrollParallax'

const styles = {
  container: css`
    background-color: ${colors.red};
    ${breakpoints({
      paddingTop: [60, 60, 140, 40, 40],
      paddingBottom: [80, 80, 40, 80, 80],
    })};
  `,
  title: css``,
  star: css`
    ${breakpoints({
      top: [0, 0, -360, -240, -240],
      right: [0, 0, 'auto', 0, 0],
      left: [0, 0, -160, 'auto', 'auto'],
    })};
  `,
  confetti: css`
    ${breakpoints({
      top: [0, 0, -400, -400, -400],
      right: [0, 0, -150, -150, -150],
    })};
  `,
  dblQuote: css`
    position: relative;
    top: 10px;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(${doubleQuotes});
    background-size: contain;
    background-repeat: no-repeat;
  `,
}
function Testimonials() {
  const star = useRef(null)

  useEffect(() => {
    const starOptions = {
      options: {
        SMtriggerElement: star.current,
        SMduration: '100%',
        SMtriggerHook: 0.8,
        GSelement: star.current,
        GSduration: 1,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const starParallax = new ScrollParallax()

    starParallax.init(
      starOptions.options,
      starOptions.timelineVarsStart,
      starOptions.timelineVarsEnd,
    )
    return () => {
      starParallax.destroyController()
    }
  }, [])
  return (
    <section css={styles.container}>
      <div className='container relative'>
        <div className='row'>
          <div className='col-lg-5'>
            <Image
              ref={star}
              lazy
              css={styles.star}
              className='d-none d-md-block'
              alt='star'
              image='about/star'
              sizes={{
                lg: {
                  width: 600,
                },
                md: {
                  width: 400,
                },
              }}
            />
          </div>
          <div className='offset-lg-1 col-lg-6 text-lg-left text-center'>
            <Fade clear cascade duration={reactRevealDuration}>
              <blockquote>
                <span css={styles.dblQuote} />
                <Title
                  as='h3'
                  size='xxxLarge'
                  weight='bold'
                  color={colors.black}
                  css={styles.title}
                >
                  I'm passionate about helping children access the
                  benefits of an amazing education, just like I did.
                </Title>
                <Text
                  color={colors.black}
                  size='large'
                  className='mt-3'
                >
                  <strong>Faisal Nasim</strong> | <span>Founder</span>
                </Text>
              </blockquote>
            </Fade>
          </div>
        </div>
        <Image
          lazy
          css={styles.confetti}
          className='d-none d-md-block'
          alt='confetti bucket'
          image='howitworks/confetti'
          sizes={{
            lg: {
              width: 261,
            },
            md: {
              width: 200,
            },
          }}
        />
      </div>
    </section>
  )
}

export default Testimonials
