import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import About from '#containers/About/About'
import heroimage from '#images/hero/about_hero.png'

const AboutPage = () => (
  <PublicLayout headerColor={colors.purple}>
    <SEO
      title='About us'
      meta={[{ name: 'theme-color', content: colors.purple }]}
      image={heroimage}
    />
    <About />
  </PublicLayout>
)

export default AboutPage
