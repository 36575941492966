import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import { Power0 } from 'gsap'
import {
  breakpoints,
  colors,
  spacer,
  mixins,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import Image from '#components/Image'
import blueShield from '#images/about/blueShield.svg'
import greenShield from '#images/about/greenShield.svg'
import pinkShield from '#images/about/pinkShield.svg'
import greyShield from '#images/about/greyShield.svg'
import { externalSites } from '#globals/routes'
import {
  reactRevealDuration,
  staggerDelay,
  reactRevealDistance,
  timelineY,
} from '#globals/constants'
import ScrollParallax from '#lib/ScrollParallax'

const styles = {
  container: css`
    position: relative;
    ${breakpoints({
      paddingTop: [80, 80, 160, 180, 180],
      paddingBottom: [30, 30, 90, 180, 180],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  text: css`
    ${spacer.mrB30}
  `,
  shield: css`
    cursor: pointer;
    width: 75px;
    @media (min-width: 480px) {
      width: 100px;
    }
  `,
  advice: css`
    margin-bottom: 50px;
    &:hover {
      opacity: 0.8;
    }
    ${mixins.transition()}
  `,
  rocket: css`
    ${breakpoints({
      top: [null, null, -240, -260, -260],
      left: [null, null, -220, -280, -280],
    })};
  `,
  scissors: css`
    ${breakpoints({
      top: [null, null, -300, -320, -320],
      right: [null, null, -200, -240, -200],
    })};
  `,
}
const advices = [
  {
    img: blueShield,
    text: 'Exam Papers Plus',
    to: externalSites.examPaperPlus,
  },
  {
    img: greenShield,
    text: 'Pretest Plus',
    to: externalSites.preTestPlus,
  },

  {
    img: greyShield,
    text: 'Mark Schemes Plus',
    to: externalSites.markSchemePlus,
  },
]
function AdviceAndTips() {
  const rocket = useRef()
  useEffect(() => {
    const rocketOptions = {
      options: {
        SMtriggerElement: rocket.current,
        SMduration: '80%',
        SMtriggerHook: 0.8,
        GSelement: rocket.current,
        GSduration: 1,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const rocketParallax = new ScrollParallax()

    rocketParallax.init(
      rocketOptions.options,
      rocketOptions.timelineVarsStart,
      rocketOptions.timelineVarsEnd,
    )
    return () => {
      rocketParallax.destroyController()
    }
  }, [])
  return (
    <section css={styles.container}>
      <div className='container relative'>
        <div className='row justify-content-center'>
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-10 text-center'>
              <Title
                as='h2'
                size='xxxxxLarge'
                weight='bold'
                color={colors.black}
                css={styles.title}
              >
                Still Curious?
              </Title>
              <Text
                size='large'
                color={colors.charcoal}
                css={styles.text}
              >
                Here are some of our other sites that you may wish to
                explore.
              </Text>
            </div>
          </Fade>
        </div>
        <div className='row pt-5 justify-content-between'>
          {advices.map((advice, i) => (
            <ExternalLink
              to={advice.to}
              className='col-md-3 col-6 d-flex justify-content-center'
              css={styles.advice}
              key={advice.text}
              target='_blank'
            >
              <Fade
                duration={reactRevealDuration}
                bottom
                distance={reactRevealDistance}
                delay={i * staggerDelay}
              >
                <figure className='text-center'>
                  <img
                    css={styles.shield}
                    src={advice.img}
                    alt={advice.text}
                  />
                  <Text
                    as='figcaption'
                    weight='bold'
                    size='large'
                    color={colors.black}
                    className='mt-4'
                  >
                    {advice.text}
                  </Text>
                </figure>
              </Fade>
            </ExternalLink>
          ))}
        </div>
        <Image
          ref={rocket}
          lazy
          css={styles.rocket}
          className='d-none d-md-block'
          alt='rocket'
          image='about/rocket'
          sizes={{
            lg: {
              width: 466,
            },
            md: {
              width: 380,
            },
          }}
        />
        <Image
          lazy
          css={styles.scissors}
          className='d-none d-md-block'
          alt='scissors'
          image='about/scissors'
          sizes={{
            lg: {
              width: 443,
            },
            md: {
              width: 380,
            },
          }}
        />
      </div>
    </section>
  )
}

export default AdviceAndTips
