import React from 'react'
import Wave from '@dqp/common/components/Wave/Wave'
import { colors } from '@dqp/common/styles/utilities'
import Hero from './Hero'
import Note from './Note'
import Testimonials from './Testimonials'
import DetailedExplanation from './DetailedExplanation'
import StillCurious from './StillCurious'

function About() {
  return (
    <>
      <Hero />
      <Wave bg={colors.purple} fill={colors.white} type='1' />
      <Note />
      <Wave bg={colors.white} fill={colors.red} type='2' />
      <Testimonials />
      <Wave bg={colors.red} fill={colors.black} type='3' />
      <DetailedExplanation />
      <Wave bg={colors.black} fill={colors.white} type='1' />
      <StillCurious />
    </>
  )
}

export default About
