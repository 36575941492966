import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import Image from '#components/Image'
import { reactRevealDuration, faisalEmail } from '#globals/constants'

const styles = {
  contaner: css`
    background-color: ${colors.black};
    ${breakpoints({
      paddingTop: [80, 80, 60, 60, 60],
      paddingBottom: [80, 80, 80, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  text: css`
    line-height: 1.5;
  `,
  img: css`
    position: relative;
    width: 100%;
    z-index: 1;
  `,
  confetti: css`
    ${breakpoints({
      right: [0, 0, 0, 0, 0],
      top: [-50, -60, -50, 0, 0],
    })};
  `,
}

function DetailedExplanation() {
  return (
    <section css={styles.contaner}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2'>
            <Image
              lazy
              css={styles.confetti}
              className='d-none d-lg-block'
              alt='confetti'
              image='about/confetti'
              sizes={{
                lg: {
                  width: 238,
                },
                md: {
                  width: 180,
                },
              }}
            />
          </div>
          <div className='col-lg-1' />
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-9'>
              <Title
                as='h2'
                size='xxxLarge'
                weight='bold'
                color={colors.white}
                css={styles.title}
              >
                What’s often standing between children and this
                opportunity is the entrance exam…
              </Title>

              <Text
                size='large'
                color={colors.white}
                css={styles.text}
              >
                This is where Daily Quest Plus comes in. It
                demystifies the examination process, giving students
                the opportunity to answer exam-style questions,
                accompanied by expert tutorials, providing
                step-by-step guidance and advice. At the same time, it
                provides you with clear context and data, highlighting
                your child’s strengths and weaknesses and helping you
                to target and focus your exam preparation.
                <br />
                <br />
                Daily Quest Plus is part of a family of sites designed
                to help your child prepare for their assessments.
                Targeted efficiency is the fundamental principle that
                underpins all our resources. We focus on exactly what
                your child needs to know, cutting out the fluff and
                making the process manageable and efficient. This
                ethos has attracted a loyal following, with hundreds
                of thousands of students using our products to get
                them where they want to be.
                <br />
                <br />
                I’m always happy to help and advise personally. So
                whether you’re a parent, tutor or teacher, feel free
                to email me directly:{' '}
                <ExternalLink
                  to={`mailto:${faisalEmail}`}
                  hoverColor='white'
                  borderColor='white'
                >
                  {faisalEmail}
                </ExternalLink>
              </Text>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default DetailedExplanation
